



































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import "firebaseui/dist/firebaseui.css";
import { Action, Getter } from "vuex-class";
import BackButton from "@/components/BackButton.vue";
import { BlogEntry, AppData, NotificationRequest, Block } from "@/types/state";
import { getBlocks } from "@/plugins/notion";

@Component({
  components: { BackButton },
})
export default class BlogShow extends Vue {
  blocks: Array<Block> = [];
  blogEntry: BlogEntry | null = null;
  loadingBlog = true;
  @Getter("loading") loading!: boolean;
  @Getter("appData") appData!: AppData;
  @Getter("blogEntries") blogEntries!: Array<BlogEntry>;
  @Getter("blogEntryFromSlug") blogEntryFromSlug!: (
    slug: string
  ) => BlogEntry | null;

  @Action("loadBlogState") loadBlogState!: () => Promise<void>;
  @Action("setPageTitle") setPageTitle!: (title: string) => Promise<void>;
  @Action("setTitle") setTitle!: (title: string) => void;
  @Action("addNotification") addNotification!: (
    request: NotificationRequest
  ) => void;

  mounted(): void {
    this.setTitle("");
    this.loadBlogState().then(async () => {
      this.blogEntry = this.blogEntryFromSlug(this.$route.params.slug);
      if (!this.blogEntry) {
        this.addNotification({
          type: "error",
          message: "The blog post does not exist",
        });
        await this.$router.push({
          name: this.$constants.ROUTE_NAMES.BLOG_INDEX,
        });
        return;
      }

      await this.setPageTitle(this.blogEntry.title);
      this.blocks = await getBlocks(this.blogEntry.id);
      this.loadingBlog = false;
    });
  }
}
