import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","xl":"6","md":"8","offset-md":"2","offset-xl":"3"}},[(_vm.loadingBlog)?_c(VProgressCircular,{staticClass:"mx-auto d-block my-16",attrs:{"size":100,"width":5,"color":"lime","indeterminate":""}}):_vm._e(),(!_vm.loadingBlog)?_c('h1',{staticClass:"text-h3 mb-8 mt-8"},[_vm._v(" "+_vm._s(_vm.blogEntry.title)+" ")]):_vm._e(),(!_vm.loadingBlog)?_c('p',{staticClass:"text-h6 text-none text--secondary"},[_vm._v(" "+_vm._s(_vm.blogEntry.intro)+" ")]):_vm._e(),(!_vm.loadingBlog)?_c('div',[_vm._l((_vm.blocks),function(block){return [(block.type === 'paragraph')?_c('p',{key:block.id,staticClass:"mb-8"},[_vm._l((block.contents),function(content){return [(content.href)?_c('a',{key:content.id,class:{ 'lime--text': _vm.$vuetify.theme.dark },attrs:{"href":content.href}},[_vm._v(_vm._s(content.text))]):(content.bold)?_c('span',{key:content.id,staticClass:"font-weight-bold"},[_vm._v(_vm._s(content.text))]):_c('span',{key:content.id},[_vm._v(_vm._s(content.text))])]})],2):_vm._e(),(block.type === 'image')?_c(VImg,{key:block.id,staticClass:"mx-auto",attrs:{"src":block.image,"contain":"","max-height":"700"}}):_vm._e(),(block.type === 'heading_2')?_c('h2',{key:block.id,staticClass:"text-h4 mb-1 mt-4"},[_vm._v(" "+_vm._s(block.contents[0].text)+" ")]):_vm._e(),(block.type === 'quote')?_c('p',{key:block.id,staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(block.contents[0].text)+" ")]):_vm._e()]})],2):_vm._e(),(!_vm.loadingBlog)?_c(VDivider,{staticClass:"lime mt-8"}):_vm._e(),(!_vm.loadingBlog)?_c('div',{staticClass:"text-center mt-4"},[_c('back-button')],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }